import MailIcon from '@mui/icons-material/Mail';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

import Button from '@/components/ui/Button/Button';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import { heroVariantPropType } from '@/constants/propTypes';
import { trackBottomCTAButtonClick } from '@/helpers/dataLayer';
import { useDirectRequestDialogContext } from '@/hooks/useDirectRequestDialog';
import useFormatMessage from '@/hooks/useFormatMessage';
import usePhoneNumber from '@/hooks/usePhoneNumber';

/**
 * BottomCTA.
 */
const BottomCTA = ({ heroVariant }) => {
  const { openDialog } = useDirectRequestDialogContext();
  const [phoneNumber, phoneNumberLink] = usePhoneNumber();
  const formatMessage = useFormatMessage();

  const handleClick = () => {
    trackBottomCTAButtonClick();
    if (heroVariant === 'category_page') {
      openDialog();
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Grid container columnSpacing={4}>
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={8}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 3, md: 5 }}>
          <Box sx={{ flex: 1 }}>
            <Button
              color="primary"
              size="large"
              data-cy="button: bottomCTA"
              onClick={handleClick}
              className="mrkt-btn_bottom-anfragen"
              startIcon={<MailIcon />}
              uppercase
              fullWidth
              disableElevation>
              {formatMessage('main_seo_cta')}
            </Button>
          </Box>
          <Stack alignItems="center" spacing={1} flex={1}>
            <Button
              color="primaryLight"
              component="a"
              href={phoneNumberLink}
              size="large"
              startIcon={
                <SvgIcon name="phone" fontSize="small" color="primary" />
              }
              fullWidth
              disableElevation>
              <span className="mrsCallTracking">{phoneNumber}</span>
            </Button>
            <Typography variant="captionRedesign" color="text.disabled">
              {formatMessage('team_availability')}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

BottomCTA.propTypes = {
  heroVariant: heroVariantPropType,
};

BottomCTA.defaultProps = {
  heroVariant: null,
};

export default BottomCTA;
